import React, { useState } from "react";
import RadioIconUnfilledSVG from "../icons/RadioIconUnfilledSVG";

const QuestionMode = ({ questions, answers }) => {
    const [presentQuestion, setPresentQuestion] = useState(0);

    const handleSelectChange = (event) => {
        const selectedIndex = event.target.selectedIndex;
        setPresentQuestion(selectedIndex);
    };

    return (
        <div className="p-5 bg-white rounded-xl shadow border border-gray-100 flex-col justify-center items-start gap-5 inline-flex">
            <div className="self-stretch flex-col justify-start items-start gap-12 flex">
                <div className="self-stretch justify-start items-center gap-12 inline-flex">
                    <select
                        className="w-[350px] px-4 py-3.5 bg-gray-50 rounded-lg border border-gray-300 flex-col justify-start items-start inline-flex"
                        value={presentQuestion}
                        onChange={handleSelectChange}
                    >
                        {questions.map((question, index) => (
                            <option key={index} value={index}>
                                {question.title}
                            </option>
                        ))}
                    </select>

                    <div className="h-8 justify-start items-center gap-3 flex">
                        <div className="grow shrink basis-0 h-8 rounded border border-gray-300 justify-start items-center flex">
                            <div
                                role="button"
                                onClick={() =>
                                    setPresentQuestion((prev) =>
                                        prev > 0 ? prev - 1 : 0
                                    )
                                }
                                className={`self-stretch px-3 py-1.5 flex-col justify-start items-center inline-flex 
        ${
            presentQuestion > 0
                ? "bg-white cursor-pointer"
                : "bg-gray-200 cursor-not-allowed"
        }`}
                            >
                                <div
                                    className={`text-center text-sm font-medium leading-tight 
        ${presentQuestion > 0 ? "text-gray-500" : "text-gray-400"}`}
                                >
                                    Прошлый
                                </div>
                            </div>

                            <div
                                role="button"
                                onClick={() =>
                                    setPresentQuestion((prev) =>
                                        prev < questions.length - 1
                                            ? prev + 1
                                            : questions.length - 1
                                    )
                                }
                                className={`self-stretch px-3 py-1.5 flex-col justify-center items-center inline-flex 
        ${
            presentQuestion < questions.length - 1
                ? "bg-[#bf1b2c]/5 cursor-pointer"
                : "bg-gray-200 cursor-not-allowed"
        }`}
                            >
                                <div
                                    className={`text-center text-sm font-medium leading-tight 
        ${
            presentQuestion < questions.length - 1
                ? "text-[#aa1d2b]"
                : "text-gray-400"
        }`}
                                >
                                    Следующий
                                </div>
                            </div>
                        </div>

                        <div className="text-center">
                            <span className="text-[#111928] text-sm font-semibold leading-[21px]">
                                {presentQuestion + 1}{" "}
                            </span>
                            <span className="text-gray-700 text-sm font-normal leading-tight">
                                из{" "}
                            </span>
                            <span className="text-[#111928] text-sm font-semibold leading-[21px]">
                                {questions.length}{" "}
                            </span>
                            <span className="text-gray-700 text-sm font-normal leading-tight">
                                вопросов
                            </span>
                        </div>
                    </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                    {answers[presentQuestion]?.answers.map((answer, index) => (
                        <div
                            key={index}
                            className="self-stretch h-[84px] p-4 bg-white rounded-xl shadow flex-col justify-start items-start gap-4 flex"
                        >
                            <div className="h-[52px] flex-col justify-start items-start gap-3 flex">
                                <div className="self-stretch rounded-lg justify-start items-start gap-2 inline-flex">
                                    <RadioIconUnfilledSVG />
                                    <div className="flex-col justify-start items-start gap-1 inline-flex">
                                        <div className="text-gray-500 text-sm font-normal leading-none">
                                            {answer.name}
                                        </div>
                                    </div>
                                </div>
                                <div className="self-stretch text-[#bf1b2c] text-base font-medium leading-normal">
                                    {answer.value} ответов
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuestionMode;
