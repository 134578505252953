const ArrowRightIconSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.9348 8.35576C13.9996 8.18561 14.0166 7.99837 13.9835 7.81775C13.9504 7.63712 13.8687 7.47121 13.7487 7.34103L10.32 3.61722C10.2409 3.5283 10.1463 3.45738 10.0417 3.40859C9.93713 3.3598 9.82465 3.33412 9.71083 3.33304C9.59701 3.33197 9.48414 3.35552 9.37879 3.40233C9.27344 3.44914 9.17774 3.51827 9.09725 3.60568C9.01677 3.69309 8.95312 3.79703 8.91002 3.91144C8.86692 4.02585 8.84523 4.14844 8.84622 4.27205C8.84721 4.39566 8.87085 4.51782 8.91578 4.6314C8.9607 4.74498 9.02601 4.84771 9.10788 4.93358L11.0743 7.06919H2.8572C2.62985 7.06919 2.41182 7.16727 2.25107 7.34186C2.09031 7.51644 2 7.75324 2 8.00014C2 8.24704 2.09031 8.48383 2.25107 8.65842C2.41182 8.83301 2.62985 8.93109 2.8572 8.93109H11.0743L9.10874 11.0658C9.02686 11.1516 8.96156 11.2544 8.91664 11.3679C8.87171 11.4815 8.84806 11.6037 8.84707 11.7273C8.84609 11.8509 8.86777 11.9735 8.91088 12.0879C8.95398 12.2023 9.01763 12.3063 9.09811 12.3937C9.1786 12.4811 9.2743 12.5502 9.37965 12.597C9.485 12.6438 9.59787 12.6674 9.71169 12.6663C9.82551 12.6652 9.93799 12.6396 10.0426 12.5908C10.1472 12.542 10.2417 12.471 10.3208 12.3821L13.7496 8.65832C13.829 8.57165 13.8919 8.46884 13.9348 8.35576Z"
                fill="#1F2A37"
            />
        </svg>
    );
};

export default ArrowRightIconSVG;
