const ExclamationMarkIconSVG = () => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.9998 3.66699C18.3739 3.66699 14.8293 4.74222 11.8144 6.75671C8.79949 8.77121 6.44966 11.6345 5.06205 14.9845C3.67445 18.3344 3.31139 22.0207 4.01878 25.577C4.72618 29.1333 6.47226 32.4 9.03622 34.9639C11.6002 37.5279 14.8669 39.274 18.4232 39.9814C21.9795 40.6888 25.6657 40.3257 29.0157 38.9381C32.3657 37.5505 35.229 35.2007 37.2434 32.1858C39.2579 29.1709 40.3332 25.6263 40.3332 22.0003C40.3278 17.1397 38.3946 12.4796 34.9576 9.04259C31.5206 5.60559 26.8605 3.67233 21.9998 3.66699ZM21.9998 31.167C21.6372 31.167 21.2828 31.0595 20.9813 30.858C20.6798 30.6566 20.4448 30.3702 20.3061 30.0352C20.1673 29.7002 20.131 29.3316 20.2017 28.976C20.2725 28.6204 20.4471 28.2937 20.7035 28.0373C20.9599 27.7809 21.2865 27.6063 21.6422 27.5355C21.9978 27.4648 22.3664 27.5011 22.7014 27.6399C23.0364 27.7786 23.3228 28.0136 23.5242 28.3151C23.7257 28.6166 23.8332 28.9711 23.8332 29.3336C23.8332 29.8199 23.64 30.2862 23.2962 30.63C22.9524 30.9738 22.4861 31.167 21.9998 31.167ZM23.8332 23.8337C23.8332 24.3199 23.64 24.7862 23.2962 25.13C22.9524 25.4738 22.4861 25.667 21.9998 25.667C21.5136 25.667 21.0473 25.4738 20.7035 25.13C20.3597 24.7862 20.1665 24.3199 20.1665 23.8337V14.667C20.1665 14.1808 20.3597 13.7144 20.7035 13.3706C21.0473 13.0268 21.5136 12.8337 21.9998 12.8337C22.4861 12.8337 22.9524 13.0268 23.2962 13.3706C23.64 13.7144 23.8332 14.1808 23.8332 14.667V23.8337Z"
                fill="#C81E1E"
            />
        </svg>
    );
};

export default ExclamationMarkIconSVG;
