import React, { useState } from "react";
import EditIconSVG from "./icons/EditIconSVG";

const RatingBlock = ({
    title = "Не взаимодействую с этим отделом",
    handlePanelTypeChange,
}) => {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div
            className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-[19px] py-[13px] rounded-[100px] bg-white"
            style={{ boxShadow: "0px 1px 2px 0 rgba(0,0,0,0.15)" }}
            data-panel-btn={title}
        >
            {!isEditing ? (
                <>
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-[#111928]">
                        {title}
                    </p>
                    <EditIconSVG onClick={() => setIsEditing(true)} />
                </>
            ) : (
                <input
                    type="text"
                    value={title}
                    onChange={(e) =>
                        handlePanelTypeChange(e.target.value, title)
                    }
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            setIsEditing(false);
                        }
                    }}
                    className="border-0"
                />
            )}
        </div>
    );
};
export default RatingBlock;
